import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import { Helmet } from "react-helmet"
import Pagination from "../components/ui/Pagination"
import ButtonLink from "../components/ui/ButtonLink"
import styled from "styled-components"
import Block from "../components/ui/Block"
import ArticlesList from "../components/ui/ArticlesList"

const Centered = styled.div`
  text-align: center;
`

export default ({ pageContext, data }) => {
  const { tag, numPages, currentPage, slug } = pageContext
  const { nodes, totalCount } = data.allMdx
  const title = `${totalCount} article${
    totalCount === 1 ? "" : "s"
  } dans la catégorie "${tag}"`

  return (
    <Layout wide>
      <Block wide>
        <Helmet title={title} />
        <h1>{title}</h1>
        <ArticlesList articles={nodes} />

        <Pagination
          current={currentPage}
          total={numPages}
          linkBase={`/tags/${slug}`}
        />

        <Centered>
          <ButtonLink to="/tags">Voir toutes les catégories</ButtonLink>
        </Centered>
      </Block>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        id
        frontmatter {
          title
          date(formatString: "D MMMM YYYY", locale: "fr-FR")
          dateEnd(formatString: "D MMMM YYYY", locale: "fr-FR")
          place
          country
          mainPicture {
            base
            childImageSharp {
              fluid(maxWidth: 400, srcSetBreakpoints: [400]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
          isPublished
          isPublic
        }
        excerpt
      }
    }
  }
`
